kv.controller('addMemberListCtrl', function ($uibModalInstance, bladeParams, data, $scope, $http, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'people', 'people_id', $scope, data);
	vm.people_type = data.people_type;
	vm.listBladeParams = bladeParams;
	vm.modal.rendered.then(() => {
		vm.element = $("#people-list-content");
		vm.getInfinitScroll({
			params: {
				people_type: vm.people_type,
				entity_id: vm.listBladeParams.groupId,
				entity: vm.listBladeParams.memberListEntity
			},
			url: 'filter/people/all_peoples_by_type'
		});

	});

	$scope.data = data;

	vm.toggleAllSelection = function toggleAllSelection() {
		var flag = vm.allSelected;
		angular.forEach($scope.result, function (entity) {
			vm.addOrRemove(entity.people_id);
			entity.selected = flag;
		});
	};

	$scope.$watch('vm.allSelected', function (value) {
		vm.toggleAllSelection();
	});


	$scope.peoplesToAdd = {};
	$scope.result = {};

	vm.addOrRemove = function addOrRemove(people_id) {
		$scope.peoplesToAdd[people_id] = 1;
		vm.save(people_id, true);
	};

	vm.peopleAdded = [];
	vm.saving = false;
	vm.save = function save(people_id, add) {
		if (($scope.data.entity === 'course' && $scope.data.entity_related === 'attendees')) {
			return vm.saveCourseAttendee(people_id, add);
		}

		vm.saving = true;
		$scope.result = vm.infinitScroll.items;
		let REST = resource.init($scope.data.entity);
		KApp.block('.modal-content');
		REST.create({
			url: $scope.data.entity + '/:entityId/:related?people_type=' + vm.people_type + "&role=participant",
			params: {
				entityId: $scope.data.entity_id,
				related: $scope.data.entity_related,
				add: add
			},
			data: [people_id]
		}).then(function (data) {
			vm.peopleAdded.push(data[0]);
			KApp.unblock('.modal-content');
			vm.saving = false;
		});
	};

	vm.saveCourseAttendee = function saveCourseAttendee(people_id, add) {
		vm.saving = true;
		$scope.result = vm.infinitScroll.items;
		let REST = resource.init($scope.data.entity);
		KApp.block('.modal-content');
		REST.create({
			url: 'course/'+ $scope.data.entity_id +'/related/attendees',
			data: [people_id]
		}).then(function (data) {
			vm.peopleAdded.push(data[0]);
			KApp.unblock('.modal-content');
			vm.saving = false;
		});
	};

	vm.cancel = function () {
		this.modal.close(vm.peopleAdded);
		$(document).find("html").css("overflow", "visible");
	};


	return vm;
});
