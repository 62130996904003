(function (window, angular) {
	var module = angular.module('kv');
	module.component('filesUpload',
		{
			bindings: {
				ngModel: "=",
				config: "=?",
				ngDisabled: "&",
				ngReadonly: "&",
				ngChange: "&",
				onStart: '&',
				onComplete: '&'
			},
			template: template,
			controller: controller
		});
	template.$inject = ["$element", "$attrs"];
	controller.$inject = ["$scope", "$element", "$attrs", "$timeout", "Upload"];

	const chunked = (arr, size) => Array.from(
		{length: Math.ceil(arr.length / size)},
		(v, i) => arr.slice(i * size, i * size + size)
	);
	const executeInSequence = (promises) => {
		return promises.reduce((promise, promiseCallback) => {
			return promise.then(promiseCallback);
		}, Promise.resolve());
	};
	const withoutHiddenFiles = (arr) => arr.filter(item => /\/\.([^\/]*)$/.test(item.webkitRelativePath) === false);
	/**
	 * atribute:
	 *   - config
	 *   -- btn-class
	 *   -- btn-i-class
	 *   -- btn-style
	 *   -- btn-text - js expression to evaluate
	 *   -- directory (webkitdirectory)
	 *   -- multiple
	 *   -- accept de forma "jpg;png"
	 *   -- max-height
	 *   -- max-size
	 *   -- show-file-list
	 *   -- file-row-class
	 *   -- no-data-class
	 *   -- no-data-text
	 *   -- show-progress-bar
	 *   -- show-remove-btn (if not exist upload-url)
	 *   -- remove-btn-class
	 *   -- upload-url
	 * @param $element
	 * @param $attrs
	 * @returns {*}
	 */

	function template($element, $attrs) {
		var attributes = $element[0].attributes;
		if (!$element[0].hasAttribute("tabindex")) $element.attr("tabindex", "0");
		var element = createElement("span", {class: "file-upload"});
		var config = $attrs.config || "$ctrl";
		config = "$ctrl.$parent" + (config.indexOf(".") > -1 ? ("." + config) : ("['" + config + "']"));
		element.style.cssText = "position: relative; overflow:hidden;display:block;";
		var browseButton = createElement("button",
			{
				type: "file",
				class: $attrs.btnClass || "form-control",
				// style: $attrs.btnStyle || "padding:0 3px",
				tabindex: $element.attr("tabindex"),
				role: "file-upload-browse",
				"ngf-select": "$ctrl.upload($ctrl.ngModel, $ctrl.$invalidFiles, $event)",
				"ng-model": "$ctrl.ngModel"

			});
		if ($attrs.btnIClass) createElement('i', {class: $attrs.btnIClass}, "", browseButton);
		if (angular.isDefined($attrs.webkitdirectory) || angular.isDefined($attrs.directory)) {
			browseButton.setAttribute("directory", "");
			browseButton.setAttribute("webkitdirectory", "");
			browseButton.setAttribute("mozdirectory", "");
			browseButton.setAttribute("multiple", "");
			// browseButton.setAttribute("ngf-select", "$ctrl.upload($ctrl.$files, $ctrl.$invalidFiles, $event)");
		}
		if (angular.isDefined($attrs.multiple)) {
			browseButton.setAttribute("multiple", "");
			// browseButton.setAttribute("ngf-select", "$ctrl.upload($ctrl.$files, $ctrl.$invalidFiles, $event)");
		}
		if ($attrs.accept) browseButton.setAttribute("ngf-accept", $attrs.accept);
		if ($attrs.maxHeight) browseButton.setAttribute("max-height", $attrs.maxHeight);
		if ($attrs.maxSize) browseButton.setAttribute("max-size", $attrs.maxSize);
		if ($attrs.ngDisabled || $attrs.ngReadonly) browseButton.setAttribute("ng-disabled", "$ctrl.ngDisabled() || $ctrl.ngReadonly()");
		browseButton.innerHTML += $attrs.btnText ? $attrs.btnText : "Choose files";
		element.appendChild(browseButton);
		if ($attrs.showFilesList !== undefined) {
			var fileDiv = createElement("div", {
				role: "file-upload-file",
				class: $attrs.fileRowClass || "row",
				"ng-repeat": "file in $ctrl.$files"
			});
			var fileNameInnerHtml = "<div ng-bind='file.name'></div>";
			var fileNameSpan = createElement("span", {role: "file-upload-file-name"});
			fileNameSpan.style.cssText = "width:100%;overflow:hidden;";
			fileNameSpan.innerHTML = fileNameInnerHtml;
			fileDiv.appendChild(fileNameSpan);
			if ($attrs.showProgressBar !== undefined) {
				var progressBar = createElement("span", {role: "file-upload-progress", style: "position:relative"});
				progressBar.innerHTML = "<span>{{(file.progress || 0)  + '%'}}</span>";
				var progressBarIndicator = createElement("div", {style: "width:{{file.progress}}%"});
				progressBar.appendChild(progressBarIndicator);
				fileDiv.appendChild(progressBar);
			}
			if (!$attrs.uploadUrl && $attrs.showRemoveBtn !== undefined) createElement("span", {class: $attrs.removeBtnClass || "fa fa-times", role: "file-remove-btn", ngClick: "$ctrl.removeFile(file)"}, "", fileDiv);
			createElement("div", {role: "files-list"}, fileDiv, element);
			if ($attrs.noDataText || $attrs.noDataClass) {
				let noData = createElement('div', {role: "no-data", ngIf: '!$ctrl.$files.length'});
				if ($attrs.noDataText) noData.innerHTML = $attrs.noDataText;
				if ($attrs.noDataClass) noData.setAttribute('class', $attrs.noDataClass);
				element.appendChild(noData);
			}

		} //else element.children[0].style.width = "100%";
		var template = element.innerHTML;
		return template;
	}

	function controller(scope, element, attrs, $timeout, Upload) {
		var $filesUploadScope =
			{
				multipleFiles: [],
				$files: undefined,
				errorMsg: "",
				errFile: undefined,
				removeFile: function (file) {
					let found = null;
					for (let i = 0; i < this.$files.length; i++) {
						if (file.$$hashKey === this.$files[i].$$hashKey) found = i;
					}
					if (found !== null) this.$files.splice(found, 1);
				},
				uploadAll: function (url, extraData) {
					let data = extraData;
					data.files = this.$files;
					var config = {
						url: url,
						method: "POST",
						data: data,
						headers: {"Content-Type": "text/csv"}
					};
					if (this.config && this.config.upload) this.config.upload(config);
					return Upload.upload(config);
				},
				readFileContent: function (file, callback) {
					let reader = new FileReader();
					reader.onload = function(evt) {
						callback(evt);
					};
					reader.readAsDataURL(file);
				},
				upload: function (files, errFiles, $event) {
					let uploadFile = this;
					let multiple = false;
					var uploadUrlNew = attrs.uploadUrl;
					let onCompleteHandler = uploadFile.onComplete;
					let onStartHandler = uploadFile.onStart;

					// call start hook
					if (onStartHandler) onStartHandler();

					if (Array.isArray(files)) {
						multiple = true;
						if (uploadFile.$files) {
							files.forEach(function (file) {
								uploadFile.$files.push(file);
							});
						} else {
							uploadFile.$files = files;
						}
					} else if (files) {
						if (uploadFile.$files) {
							uploadFile.$files.push(files);
						} else {
							uploadFile.$files = [files];
						}
					}

					uploadFile.multipleFiles = uploadFile.$files;
					uploadFile.errorMsg = '';

					uploadFile.errFile = errFiles && errFiles[0];
					if (uploadFile.config.onChange) uploadFile.config.onChange($event);
					if (uploadFile.$files.length === 0) {
						return;
					}
					if (multiple && attrs.hasOwnProperty('directory')) {
						const promises = chunked(withoutHiddenFiles(uploadFile.$files), 10)
							.map((files, index) => new Promise((resolve, reject) => {
								const paths = files.map(file => file.webkitRelativePath || null);
								const config = {
									url: uploadUrlNew,
									method: 'POST',
									data: {files: files, paths: paths},
									headers: {'Content-Type': 'text/csv'}
								};
								if (uploadFile.config && uploadFile.config.upload) uploadFile.config.upload(config);
								let loaderObj = uploadFile.config.loaderSelector ? loader(uploadFile.config.loaderSelector) : null;
								uploadFile.config.loaded = false;

								Upload.upload(config).then(response => {
										if (response && response.status) {
											$timeout(() => {
												uploadFile.config.loaded = true;
												if (uploadFile.config && uploadFile.config.uploaded) {
													uploadFile.config.uploaded(response.data, loaderObj);
												}
											});
											files.forEach(file => {
												uploadFile.$files.splice(uploadFile.$files.indexOf(file), 1);
												uploadFile.multipleFiles.splice(uploadFile.multipleFiles.indexOf(file), 1);
											});
											resolve();
										}
									}, response => {
										if (uploadFile.config && uploadFile.config.uploadError) uploadFile.config.uploadError(response.data);
										reject();
									}
								);
						}));

						executeInSequence(promises).then(() => {
							console.log('All files uploaded');
							if (onCompleteHandler) {
								onCompleteHandler();
							}
						});
						return;
					}
					if(attrs.uploadUrl) {
						let currentIndex = 0;
						uploadFile.$files.forEach(file => {
							const config = {
								url: uploadUrlNew,
								method: 'POST',
								data: { file: file },
								headers: { 'Content-Type': 'text/csv' }
							};
							let loaderObj = uploadFile.config.loaderSelector ? loader(uploadFile.config.loaderSelector) : null;
							if (uploadFile.config && uploadFile.config.upload) uploadFile.config.upload(config);

							Upload.upload(config).then(response => {
								if (response && response.status) {
									$timeout(() => {
										uploadFile.config.loaded = true;
										if (uploadFile.config && uploadFile.config.uploaded) {
											uploadFile.config.uploaded(response.data, loaderObj);
										}
									});
									uploadFile.$files = uploadFile.multipleFiles = [];
								}
								// Increment currentIndex in the success handler
								currentIndex++;
								if (onCompleteHandler) onCompleteHandler();
							}, response => {
								if (response && response.status > 0) {
									file.errorMsg = response.status + ': ' + response.data;
								}
								if (uploadFile.config && uploadFile.config.uploadError) {
									uploadFile.config.uploadError(response.data);
								}
								// Increment currentIndex in the error handler
								currentIndex++;
								if (onCompleteHandler) onCompleteHandler();
							}, evt => {
								file.progress = Math.min(100, 100.0 * parseInt(evt.loaded) / parseInt(evt.total));
							});
						});
                    }
				}
			};
		scope.$ctrl = angular.extend(scope.$ctrl, $filesUploadScope);
		if (attrs.config && scope.$ctrl.config) {
			scope.$ctrl.config.$ctrl = scope.$ctrl;
		}
	}
})(window, angular);
