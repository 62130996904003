kv.controller('expenseListCtrl', function ($scope, $element, $attrs, $injector, $http, resource, $filter) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

	vm.primaryKey = 'expense_id';
	vm.entity = 'expense';

	let date_from = moment().subtract(1, 'years').startOf('year');
	vm.getInfinitScroll({
		search: {
			date_from: date_from.format(vm.API.DATE_FORMAT),
			scope: 'ALL',
		},
	});

	$scope.expenseTypes = [
		{
			name: $filter('trans')('INTERNAL'),
			id: "internal"
		},
		{
			name: $filter('trans')('PER_PROJECT'),
			id: "per_project"
		},
		{
			name: $filter('trans')('NOT_REVIEWED'),
			id: "not_reviewed"
		},
		{
			name: $filter('trans')('APPROVED') + "e",
			id: "approved"
		}
	];
	Object.defineProperty(vm, 'has_filters', {
		get: function () {
			return (vm.infinitScroll.search.customer || vm.infinitScroll.search.project || vm.infinitScroll.search.date_from) ? 1 : 0;
		}
	});


	vm.deleteFromJournal = function ($index) {
		resource.init('sun_export_detail').delete({
			url: 'sun_export_detail/expense/' + vm.infinitScroll.items[$index][vm.primaryKey],
		});
	};

	var loadExpenses = function loadExpenses() {
		vm.infinitScroll.search.freshData = new Date().getTime();
	};

	vm.edit = function (entity, index, duplicate) {
		vm.openModal({
			templateUrl: "expense-edit",
			size: 'lg',
			controller: "ExpenseEditCtrl",
			controllerAs: "vm",
			resolve: {
				data: function () {
					return {
						index: index,
						result: entity,
						defaultObject: JSON.parse(vm.bladeParams.defaultObject),
						message: vm.message
					};
				},
				params: {entity: entity, index: index, duplicate: duplicate, defaultObject: JSON.parse(vm.bladeParams.defaultObject)},
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			loadExpenses();
			if (!response) return;
			if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
			else angular.extend(vm.infinitScroll.items[response.index], response.entity);
			if (response.saveType == 1){
				vm.infinitScroll.items.unshift(response.entity);
				vm.edit(null, -1);
			}
			else if (response.saveType == 2){
				vm.infinitScroll.items.unshift(response.entity);
				vm.edit(response.entity, 0, true);
			}

		});
	};


	let params = queryParamToJson();
	if (params.create_from) {
		if(params.create_from == 'efactura_received'){
			vm.edit(params, -1);
		}else{
			vm.edit(null, -1);
		}
	}
	
	vm.moveEntities = function () {
		vm.openModal({
			templateUrl: 'expense-list-move',
			controller: ExpenseApprovalListMoveCtrl,
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: vm.infinitScroll.search,
						countLines: vm.infinitScroll.total
					};
				}
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
		});
	};

	vm.delete = function ($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("expense/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	return vm;
});
