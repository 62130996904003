kv.controller('courseEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'course_id';
    vm.entity = 'course';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);

    vm.getData = function() {
        $http.get("/course/" + page_entity_id).then(function(response) {
            vm.data = response.data;
            if (vm.data.trainers) {
                angular.forEach(vm.data.trainers, function (v, i) {
                    vm.data.trainers[i].id = v.pivot.trainer_id;
                    vm.data.trainers[i].name = v.full_name;
                });
            }

            if (vm.data.departments) {
                angular.forEach(vm.data.departments, function (v, i) {
                    vm.data.departments[i].id = v.department_id;
                    vm.data.departments[i].name = v.department_name;
                });
            }
            let date = moment(vm.data.date);
            vm.data.date = date.format('YYYY-MM-DD');
            // $scope.$broadcast('dataLoaded');
        });
    };

    if(page_identifier == 'edit') {
        vm.isNewRecord = false;
        vm.getData();
    } else {
        vm.isNewRecord = true;
        vm.data = defaultObjects;
    }


    vm.newEntity = function (entity_type) {
        // prevent default
        if (!vm.data) {
            vm.data = defaultObjects;
        }

        if (!vm.data[entity_type]) {
            return vm.data[entity_type] = [{}];
        }
        return vm.data[entity_type].push({});
    };

    vm.deleteEntity = function ($index, entity_type, id) {
        if(!id) {
            return vm.data[entity_type].splice($index,1);
        }
        
        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity_type + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity_type].splice($index,1);
            });
        }
    };

    vm.deleteMorphedEntity = function($index, entity, resultEntity, id) {
        if (!id) {
            return vm.data[resultEntity].splice($index, 1);
        }
        if(page_entity_id)
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function () {
                vm.data[resultEntity].splice($index, 1);
            });
    };

    vm.filesUpload = {
        loaded: true,
        uploaded: function (response) {
            vm.getData();
        }
    };

	return vm;
});
