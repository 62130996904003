kv.controller('ActivitiesReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var params = kvUtils.getAllQueryParams();
    var budget_item_url = vm.bladeParams.budgetItemUrl;
    vm.action = {
        addEditEntity: addEditEntity
    }
    // get the budget items
    vm.budget_items = {};
    $http.get(budget_item_url)
        .then(function (response) {
            vm.budget_items = response.data.data;
            $scope.$broadcast('dataLoaded');
    });

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');

        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }

    $(document).ready(function() {
        var t = $('#results').DataTable({
			  fixedHeader: {
                header: false,
                footer: true
            },
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "bShowCounters": true
        });

        t.on( 'order.dt search.dt', function () {
            t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                cell.innerHTML = i+1;
            } );
        } ).draw();
    });

    function addEditEntity(ev, $activity_id){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'activity-edit',
            controller: function($uibModalInstance, cmdate, validator, data, resource) {
                var vm = this;

                vm.budget_items = data.budget_items;
                vm.activity = data.activity;

                vm.primaryKey = 'activity_id';
                vm.entity = 'activity';

                // get the selected activity data
                var REST = resource.init(vm.entity);
                REST.get({
                    link: "activity/",
                    id: $activity_id
                }).then(function(res) {
                    vm.activity = {};
                    vm.activity.activity_id = res.activity_id;
                    if(res.budget_item_id) {
                        vm.activity.budget_item_id = res.budget_item_id;
                        vm.activity.budget_item = {
                            budget_item_name: res.budget_item.budget_item_name,
                            budget_item_code: res.budget_item.budget_item_code,
                        };
                    }
                })

                vm.action = {
                    save: save,
                    cancel: cancel,
                };

                // modal save function (button)
                function save() {
                    REST.update({
                        id: $activity_id,
                        data: vm.activity
                    }).then(function (response) {
                        $uibModalInstance.close({response: response});
                        location.reload();
                    });
                }

                // modal cancel function (button)
                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: function() {
                    return {
                        activity: vm.activity,
                        budget_items: vm.budget_items
                    };
                }
            }
        });

        modalInstance.result.then(function(data) {
            return vm.activity = data.response;
        });
    };

	return vm;
});
