kv.controller('invoiceViewEfacturaDetailsCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'efactura';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getEfacturaDetailsList = function () {
        if(vm.bladeParams.entityId) {
            let efacturaListRest = resource.init('efactura');
            efacturaListRest.get({
                params: {
                    invoice_id: vm.bladeParams.entityId,
                    orderBy: 'efactura_details',
                }
            }).then(function (response) {
                vm.efacturaList = response;
            });
        }
    };

    vm.getEfacturaDetailsList();

    vm.sendInvoiceToAnaf = function (index, entity) {
        let rest = vm.getInjection('resource').init('invoice');
        rest.update({
            url: "invoice/" + entity.invoice_id + "/sendInvoiceToAnaf"
        }).then(function (data) {
            if(data.error && data.error.error_code === 1){
                toastr.error(data.error.message);
            }
            else {
                window.location.reload();
            }
        });
    };


    vm.edit = function ($index, entity, $id) {
        let isNewRecord = !entity;
        vm.openModal({
            templateUrl: "efactura-edit",
            controller: 'efacturaEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: function () {
                    return {
                        index: $index,
                        entity: entity,
                        id: $id
                    };
                },
                saveCallback: function () {
                    return function(response){
                        window.location = "/efactura";
                        response.modal.close();
                    }
                },
            }
        });
    }

    vm.deleteEfactura = function (invoice_id) {
        vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
            .then(function () {
                let REST = resource.init('efactura');
                REST.delete({
                    url: 'invoice/efactura/' + invoice_id,
                }).then(function () {
                    vm.getEfacturaDetailsList();
                    window.location = "/invoice/" + invoice_id;
                });
            });
    };

    vm.markEfacturaAsProcessed = function (efactura_id) {
        vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_YOU_WANT_TO_MARK_EFACTURA_AS_PROCESSED"))
            .then(function () {
                let REST = resource.init('efactura');
                REST.post({
                    url: 'efactura/mark_efactura_as_processed/' + efactura_id,
                }).then(function () {
                    vm.getEfacturaDetailsList();
                    window.location.reload();
                });
            });
    };


    return vm;
});

