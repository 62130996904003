kv.controller('courseViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, Session, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'course_id';
	vm.entity = 'course';
	vm.entityType = 'course';
	vm.entityId = vm.bladeParams.courseId;

	vm.attendees = [];
	vm.statusFilter = 'ALL';
	vm.userRating = 3;
	vm.userComments = '';
	vm.filesCount = 0;
	vm.pendingFilesCount = 0;
	vm.completedFilesCount = 0;
	vm.canManageAttendees = vm.bladeParams.canManageAttendees;
	vm.loadingInProgress = false;

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.reloadTooltip = function($index) {
		setTimeout(function() {
			$('#tooltip-' + $index).tooltip();
		}, 200);
	};

	$scope.range = function(min, max, step) {
		step = step || 1;
		var input = [];
		for (var i = min; i <= max; i += step) {
			input.push(i);
		}
		return input;
	};

	var REST = resource.init();

	$('#media-player').on('hide.bs.modal', function () {location.reload(); });

	vm.confirmAbandon = function(url) {
		vm.confirm('Confirm', vm.trans('LANG.CONFIRM_ABANDON_QUESTION')).then(response => {
			if (response !== 'ok') return;
			window.location.href = url;
		});
	};

	vm.confirmComplete = function () {
		var modal = $('#rate');
		$(modal).modal('show');
	};

	/**
	 * set rating value
	 * todo: is this used??
	 * @param i
	 */
	vm.setRate = function(i) {
		console.log(i);
		$("#rate" + i).addClass('btn-brand').removeClass('btn-secondary');
		$('.modal#rate #inputRate').val(i);
	};

	/**
	 * load list of attendees
	 */
	vm.getAttendees = function() {
		REST.getArray({
			url: 'course/' + vm.entityId + '/related/attendees'
		}).then(function (res) {
			vm.attendees = res;
			vm.attendees_count = res.length;
		});
	};

	/**
	 * filter attendee list by status
	 * todo: not currently working
	 * @param attendee
	 * @returns {boolean}
	 */
	vm.statusFilterFn = function (attendee) {
		// If "All" is selected or no filter applied, show all attendees
		if (!vm.statusFilter || vm.statusFilter === 'ALL') {
			return true;
		}

		// Match the selected status with the attendee's status
		return attendee.status === vm.statusFilter;
	};

	/**
	 * add attendee to list
	 */
	vm.addAttendee = function () {
		vm.openModal({
			templateUrl: "people-list",
			controller: 'addMemberListCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				bladeParams: function () {
					return vm.bladeParams;
				},
				data: function () {
					$(document).find("html").css("overflow", "hidden");
					return {
						people_type: 'user',
						entity_id: vm.entityId,
						entity: "course",
						entity_related: "attendees",
					};
				}
			}
		}).then((result) => {
			if (result.length) vm.getAttendees();
		});
	};

	/**
	 * remove attendee from list
	 * @param $index
	 */
	vm.deleteAttendee = function ($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then((response) => {
			if (response !== 'ok') return;
			let REST = resource.init('course');
			let attendeeId = vm.attendees[$index].id;

			REST.delete({
				url: 'course/' + vm.entityId + '/related/attendees/' + attendeeId,
			}).then(function (data) {
				vm.attendees.splice($index, 1);
				vm.attendees_count = vm.attendees.length;
			});
		});
	};

	/**
	 * get course stats
	 */
	vm.getStats = function() {
		REST.get({
			url: 'course/' + vm.entityId + '/stats'
		}).then(function (res) {
			vm.stats = res;
		});
	};

	/**
	 * get list of attached files
	 */
	vm.getFiles = function() {
		REST.getArray({
			url: 'course/' + vm.entityId + '/related/files'
		}).then(function (res) {
			vm.files = res;
			vm.filesCount = res.length;
			vm.pendingFilesCount = res.filter(file => file.completed === false).length;
			vm.completedFilesCount = res.filter(file => file.completed === true).length;
		}).catch(function (err) {
			console.log(err);
		});
	};

	/**
	 * set course status to completed
	 * extract rating and comments from rating form
	 */
	vm.markCompleted = function() {
		var rates = $('#inputRate').val();  // Get the rating from the hidden input
		var comm = $('textarea[name="comments"]').val(); // Get the comments from the textarea

		var data = {
			rating: rates,
			comments: comm
		};

		REST.post({
			url: 'course/' + vm.entityId + '/actions/mark_completed',
			data: data
		}).then(function (res) {
			window.location.reload();
			//vm.confirmComplete();
		});
	};

	/**
	 * set course status to in progress (started)
	 */
	vm.markStarted = function() {
		REST.post({
			url: 'course/' + vm.entityId + '/actions/mark_started',
		}).then(function (res) {
			window.location.reload();
		});
	};

	/**
	 * set course status to in progress
	 */
	vm.markInProgress = function() {
		REST.post({
			url: 'course/' + vm.entityId + '/actions/mark_in_progress'
		}).then(function (res) {
			window.location.reload();
		});
	};

	/**
	 * set course status to abandoned
	 */
	vm.markAbandoned = function() {
		vm.confirm('Confirm', vm.trans('LANG.CONFIRM_ABANDON_QUESTION')).then(response => {
			if (response !== 'ok') return;
			REST.post({
				url: 'course/' + vm.entityId + '/actions/mark_abandoned'
			}).then(function (res) {
				window.location.reload();
			});
		});
	};

	/**
	 * stream download a course file
	 * @param file
	 */
	vm.downloadFile = function(file) {
		REST.get({
			url: 'course/' + vm.entityId + '/download/' + file.id
		}).then(function (res) {
			if (res.status === 'error') {
				vm.error(res.message);
				return;
			}
			console.log(res);
			window.open(res.link, '_blank');
		}).finally(function () {
			vm.getFiles();
			window.location.reload();
		});
	};

	/**
	 * delete a course file
	 * @param file
	 */
	vm.deleteFile = function (file) {
		REST.delete({
			url: 'files/course/' + vm.entityId + '/delete/' + file.id
		}).catch(function (err) {
			console.log(err);
		}).finally(function () {
			vm.getFiles();
		});
	};

	/**
	 * reload files after upload
	 */
	vm.uploadCompletedHandler = function () {
		vm.loadingInProgress = false;
		vm.getFiles();
	};

	vm.uploadStartedHandler = function () {
		console.log('starting upload');
		vm.loadingInProgress = true;
	};

	/**
	 * load data
	 */

	// load files
	vm.getFiles();

	// load extras, if user can manage attendees
	if(vm.bladeParams.canManageAttendees) {
		vm.getAttendees();
		vm.getStats();
	}

	vm.statuses = [
		{
			name: $filter('trans')('NOT_STARTED'),
			id: "NOT_STARTED"
		},
		{
			name: $filter('trans')('IN_PROGRESS'),
			id: "IN_PROGRESS"
		},
		{
			name: $filter('trans')('COMPLETED'),
			id: "COMPLETED"
		},
		{
			name: $filter('trans')('ABANDONED'),
			id: "ABANDONED"
		},
	];
	return vm;
});
