
angular.module('kv').directive('starRating', function () {
    return {
        restrict: 'E',
        scope: {
            rating: '=' // Bind the rating value to the directive
        },
        template: `
            <span class="rating align-middle">
                <i ng-repeat="val in [1, 2, 3, 4, 5]" 
                   class="fa rated" 
                   ng-class="{'fa-star': floorRating >= val, 'fa-star-half-alt': floorRating < val && ceilRating >= val}">
                </i>
            </span>
        `,
        link: function (scope) {
            scope.$watch('rating', function (newValue) {
                if (newValue != null) {
                    scope.floorRating = Math.floor(newValue); // Full stars
                    scope.ceilRating = Math.ceil(newValue); // Rounding for half stars
                } else {
                    scope.floorRating = 0;
                    scope.ceilRating = 0;
                }
            });
        }
    };
});