kv.controller('ExpenseEditCtrl', function ($uibModalInstance, params, $scope, $injector, $http, validator, resource, cmdate, $uibModal, $rootScope, $filter) {
    var vm = new modalCtrl($uibModalInstance, $injector, 'expense', 'expense_id', $scope, params, "expense-edit-params");
    vm.data = {};
    vm.exchangeRateError = 0;
    vm.customerLanguage = vm.API.user.language.toUpperCase();
    vm.isNewRecord = params.index == -1 || params.duplicate;
    if (vm.params.duplicate) vm.title = vm.trans('LANG.DUPLICATE');
    else if (vm.params.index == -1) vm.title = vm.trans('LANG.ADD') + ' ' + $filter('trans')('LANG.EXPENSES').toLowerCase();
    else vm.title = vm.trans('LANG.EXPENSE_EDIT');
    if (params.editFromEmployeeAccount) {
        vm.title += "<br/>" + params.employee_account.number + "/" + moment(params.employee_account.date).format(API.DATE_FORMAT) + " (" + vm.trans(params.employee_account.sys_employee_account_status.status_name) + ")";
    }
    let expense_id= params.entity ? params.entity.expense_id : null;

    vm.quickAddCustomer = function () {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'customer';
                }
            }
        }).result.then(function (result) {
            vm.data.customer_id = result.customer_id;
            vm.data.customer = {
                customer_name: result.customer_name
            };
        });
    };

    vm.getDefaultVatType = function(){
        if(params.entity && params.entity.create_from === "efactura_received"){
            if(params.entity.vat_type_id !== '' && params.entity.vat_percent !== '' && params.entity.vat_code !== '') {
              // vm.data.vat_type_id = params.entity.vat_type_id;
                vm.data = {
                    vat_type_id: params.entity.vat_type_id,
                    vat_percent: params.entity.vat_percent,
                    expense_vat_type: {
                        vat_code: params.entity.vat_code,
                        vat_percent: params.entity.vat_percent,
                        vat_type_id: params.entity.vat_type_id,
                    }
                };
            } else {
                var REST = resource.init('expense_vat_type/list');
                REST.get({
                    params: {
                        vat_code: params.entity.vat_code
                    }
                }).then(function (data) {
                    var vat_type = data.data;
                    vm.data.vat_type_id = vat_type[0].vat_type_id;
                    vm.data.expense_vat_type ={};
                    vm.data.expense_vat_type.vat_code = vat_type[0].vat_code;
                    vm.data.vat_percent = vat_type[0].vat_percent;
                });
            }
        }else{
            $http.get("/expense_vat_type").then(function(response) {
                vm.expensVatType = response.data;
                angular.forEach(vm.expensVatType.data, function (v, i) {
                    if(v.is_default === 1){
                        vm.data.vat_type_id = v.vat_type_id;
                        vm.data.expense_vat_type ={};
                        vm.data.expense_vat_type.vat_code = v.vat_code;
                        vm.data.vat_percent = v.vat_percent;
                    }
                });
            });
       }
    }
    vm.getDefaultVatType();

    vm.quickAddSupplier = function () {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'supplier';
                }
            }
        }).result.then(function (result) {
            vm.data.supplier_id = result.customer_id;
            vm.data.supplier_name = result.contact_name;
        });
    };

    vm.setDefaultCurrentAccountingPeriod = function () {
        if (!vm.data.expense_ext || !vm.data.expense_ext.accounting_period_id) {
            if (!vm.data.expense_ext) {
                vm.data.expense_ext = {};
            }
            if(params.current_accounting_period){
                vm.data.expense_ext.accounting_period_id = params.current_accounting_period.id;
                vm.data.expense_ext.accounting_period = {
                    code: params.current_accounting_period.name
                };
            }
            if ([3, 4, 5, 6].indexOf(parseInt(params.employee_account.type_id)) > -1) {
                vm.data.expense_ext.status_id = 4;
            }
        }
    };

    $scope.$watch('[vm.data.is_internal]', function (values) {
        if(vm.data.billing_type == null){
            if (values == true) { // is_internal
                vm.data.billing_type = 'NB'
            } else {
                vm.data.billing_type = 'BLB'
            }
        }
    }, true);

    $scope.$watch('[vm.data.expense_type_id]', function (newValue, oldValue) {
        vm.expenseTypeList = function () {
            var tmp = null;
            $.ajax({
                'async': false,
                'type': "GET",
                'dataType': 'json',
                'url': "../../expense_type/" + vm.data.expense_type_id,
                'success': function (response) {
                    tmp = response;
                }
            });
            return tmp;
        }();
        vm.data.price = 0;
        if (vm.expenseTypeList.unit_currency && vm.expenseTypeList.unit_currency !== null ) {
            vm.data.price = vm.expenseTypeList.unit_price;
            // forteaza currency = valuta din tip cheltuiala, doar daca NU esti in decont, altfel, vezi in expense-edit-details.blade.php
            if (!params.editFromEmployeeAccount) {
                vm.data.currency = vm.expenseTypeList.unit_currency;
            }
        }
        if (typeof vm.data.expense_ext == 'undefined') {
            vm.data.expense_ext = {};
        }
        if (vm.expenseTypeList.account_expense) {
            vm.data.expense_ext.accounting_ref_id = vm.expenseTypeList.account_expense.account_id;
            vm.data.expense_ext.account = vm.expenseTypeList.account_expense.account_number + ' - ' + vm.expenseTypeList.account_expense.account_name;
        }
        if (vm.expenseTypeList.account_income) {
            vm.data.expense_ext.income_account_id = vm.expenseTypeList.account_income.account_id;
            vm.data.expense_ext.income_account = vm.expenseTypeList.account_income.account_number + ' - ' + vm.expenseTypeList.account_income.account_name;
        }
    }, true);

    $scope.$watch('[vm.data.supplier_id, vm.data.tenant_fiscal_entity_id]', function (newValue, oldValue) {
        // wait for data to be received then request the customer_fiscal_entity_account
        if (vm.data.supplier_id) {
            vm.rest.getArray({
                url: 'customer_fiscal_entity/'+ vm.data.supplier_id +'/related/accounts',
                params: {
                    tenant_fiscal_entity_id: vm.data.tenant_fiscal_entity_id
                }
            }).then(function (res) {
                if (res.length > 0) {
                    vm.supplier_tenant_fiscal_entity = res[0].tenant_fiscal_entity_id;
                    vm.supplier_account_number = res[0].account_number;
                    vm.customer_fiscal_entity_account_warning = 0;
                } else {
                    vm.customer_fiscal_entity_account_warning = 1;
                }
            });
        }
    }, true);

    $scope.$watch('[vm.data.user_id]', function (values) {
        if(values && $rootScope.lookup != null && $rootScope.lookup.user != null){
            vm.data.expense_ext.master_practice_id = $rootScope.lookup.user.all_user.master_practice_id;
            if(!vm.data.expense_ext.master_practice){
                vm.data.expense_ext.master_practice = {};
            }
            vm.data.expense_ext.master_practice.master_practice_name = $rootScope.lookup.user.all_user.master_practice_name;
        }
    }, true);

    // set default expense vat type, if employee account is of type INTERNAL
    vm.getDefaultVatType = function () {
        if (params.editFromEmployeeAccount && params.employee_account.type_id == 5) {
            params.vat_type_id = params.employee_account.sys_employee_account_type.expense_vat_type_id;
            vm.data.vat_type_id = params.vat_type_id;
            vm.data.expense_vat_type = function () {
                var tmp = null;
                $.ajax({
                    'async': false,
                    'type': "GET",
                    'dataType': 'json',
                    'url': "/expense_vat_type/" + params.vat_type_id,
                    'success': function (response) {
                        tmp = response;
                    }
                });
                return tmp;
            }();
        }
    }

    if (vm.params.index > -1) {
        vm.modal.rendered.then(() => {
            if (!vm.dataHasLoaded) KApp.block('.modal-content');
        });
        
        // listen for the event of 'document_storage_loaded' so we know when the child component has loaded
        // then we trigger the load_files method
        $scope.$on('document_storage_loaded', () => {
            $rootScope.$broadcast('load_files', 'expense', expense_id);
        });
        $http.get("/expense/" + vm.params.entity.expense_id)
            .then(function (response) {
                vm.dataHasLoaded = true;
                KApp.unblock('.modal-content');

                if(params.invoice_data && !params.duplicate){
                    // only allow edit if invoice status = DRAFT
                    vm.readonly = params.invoice_data.invoice_status_id != 1; //status != DRAFT
                } else {
                    vm.readonly = response.data.invoice_id != null;
                }


                vm.data = response.data;
                vm.customerLanguage = (response.data.project_id ? response.data.project.customer_fiscal_entity.customer_fiscal_entity_ext.communication_language : null);
                vm.expense_status = (vm.data.expense_ext ? vm.trans(vm.data.expense_ext.status.status_name) : '');

                //check if invoice_id is null -> fields are disabled

                if (vm.data.customer == null) vm.data.customer = {};
                if (!vm.data.customer && !vm.data.customer.customer_name) vm.data.customer.customer_name = '-';

                if (vm.data.project_id) {
                    if (vm.data.project.customer_fiscal_entity_id) {
                        if (vm.data.project.customer_fiscal_entity.customer_fiscal_entity_ext.has_ledes) {
                            vm.project_has_ledes = vm.data.project.customer_fiscal_entity.customer_fiscal_entity_ext.has_ledes;
                        } else {
                            vm.project_has_ledes = 0;
                        }
                    }
                } else {
                    vm.project_has_ledes = 0;
                    vm.data.project = {};
                    if (!vm.data.project.project_name) {
                        vm.data.project.project_name = '-';
                    }
                    if (!vm.data.project.project_number) {
                        vm.data.project = {};
                        vm.data.project.project_number = '-';
                    }
                }
                vm.data.project.project_name = vm.data.customer.customer_name + ' - ' + vm.data.project.project_name + ' - ' + vm.data.project.project_number;

                if (params.duplicate) {
                    vm.expense_id = expense_id = null;
                    delete vm.data.expense_id;
                    delete vm.data.expense_ext.expense_ext_id;
                    delete vm.data.invoice.invoice_id;
                    delete vm.data.invoice;
                    delete vm.data.invoice_id;
                    delete vm.data.created_by;
                    delete vm.data.created_at;
                    delete vm.data.updated_by;
                    delete vm.data.updated_at;
                    vm.readonly = false;
                }
                if (params.editFromEmployeeAccount) {
                    vm.setDefaultCurrentAccountingPeriod();
                }

                $scope.$broadcast('dataLoaded');
                setTimeout(function () {
                    vm.calculateValues();
                }, 1000);
                try {
                    vm.data.expense_ext.income_account.full_name = vm.data.expense_ext.income_account.account_number + ' - ' + vm.data.expense_ext.income_account.account_name
                    vm.data.expense_ext.account.full_name = vm.data.expense_ext.account.account_number + ' - ' + vm.data.expense_ext.account.account_name
                } catch (e) {
                }

                if (params.editFromEmployeeAccount) {
                    vm.data.internal_employee_account = params.employee_account.type_id;
                }
            });
        KApp.unblock('.modal-content');

    } else {
        vm.data = {
            user_id: API.user.user_id,
            user: {
                full_name: API.user.full_name
            },
            tenant_fiscal_entity_id: API.user.user_ext.tenant_fiscal_entity_id,
            tenant_fiscal_entity: {
                tenant_fiscal_entity_name: API.user.user_ext.tenant_fiscal_entity.tenant_fiscal_entity_name
            },
            expense_ext: {
                allow_manual_input: 0
            }
        };
        if (API.user.master_practice_id) {
            vm.data.expense_ext = {
                master_practice_id: API.user.master_practice_id,
                master_practice: {
                    master_practice_name: API.user.master_practice.master_practice_name
                }
            };
        }
        vm.data.expense_type_id = '';
        vm.data.recipient = "own_company";
        vm.data.billing_type = "BLB";
        vm.data.is_billable = true;
        vm.data.is_internal = false;
        vm.data.is_paid = false;
        if(params.entity && params.entity.create_from === "efactura_received"){
            vm.newExpense = params.defaultObject;
            vm.data = {
                user_id: API.user.user_id,
                user: {
                    full_name: API.user.full_name
                },
                tenant_fiscal_entity_id: params.entity.tenant_fiscal_entity_id,
                tenant_fiscal_entity: {
                    tenant_fiscal_entity_name: params.entity.tenant_fiscal_entity_name,
                },
                expense_invoicing_type_id: vm.newExpense.expense_invoicing_type_id,
                expense_invoicing_type: {
                    name: vm.newExpense.expense_invoicing_type.name,
                },
                expense_ext: {},
                date: params.entity.date,
                total_amount: params.entity.total_amount,
                currency: params.entity.currency,
                invoice_number: params.entity.invoice_received_number,
                supplier_id: params.entity.supplier_id,
                supplier_name: params.entity.supplier_name,
                comments: params.entity.comments,
                efactura_received_id: params.entity.efactura_received_id,
                invoice_date: params.entity.payment_date,
                vat_type_id: params.entity.vat_type_id,
                vat_percent: params.entity.vat_percent,
                expense_vat_type: {
                    vat_code: params.entity.vat_code,
                    vat_percent: params.entity.vat_percent,
                    vat_type_id: params.entity.vat_type_id,
                },
            };
            vm.data.expense_type_id = '';
            vm.data.recipient = "own_company";
            vm.data.billing_type = "BLB";
            vm.data.is_billable = true;
            vm.data.is_internal = false;
            vm.data.is_paid = false;
        }else{
            if (params.editFromEmployeeAccount) {
                vm.data.has_purchasing_invoice = true;
                vm.data.internal_employee_account = params.employee_account.type_id;
                vm.data.currency = params.employee_account.currency ? params.employee_account.currency : API.user.tenant.legal_currency;
                vm.setDefaultCurrentAccountingPeriod();
            } else {
                vm.data.currency = API.user.tenant.legal_currency;
                vm.data.date = moment();
                setTimeout(function () {
                    vm.calculateValues();
                }, 1000);
            }
            vm.getDefaultVatType();
        }
    }

    vm.allowChange = function () {
        if (vm.bladeParams.allowedToChange == "1") return true;
        if (vm.bladeParams.hasAccessToUpdate == "1") return vm.expense_status_id == 1;
        if (API.user.user_id == vm.data.created_by) return vm.expense_status_id == 1;
    };

    /**
     * ia expense_id si face update cu statusul in expense_ext
     * @param  status_id
     * @return
     */
    vm.changeExpenseStatus = function (status_id) {
        resource.init("expense_ext").update({
            url: vm.bladeParams.expenseExtIndexAction + "/" + vm.data.expense_ext.expense_ext_id,
            data: {
                status_id: status_id

            }


        }).then(function (data) {
            vm.expense_status_id = data.status_id;
            location.reload();
        });
    };

    vm.expenseTypeList = {};
    vm.data.expense_type_id = '';
    if(vm.data.expense_id){
        $scope.$watch('lookup.expense_vat_type.all_expense_vat_type', function (value) {
            if (value) {
                vm.data.vat_percent = value.vat_percent;
            }
        });
        vm.data.vat_percent = vm.data.expense_vat_type.vat_percent;
    }

    $scope.$watch('vm.data.is_internal', function (value) {
        if (value) {
            vm.data.billing_type = "NB";
            vm.data.customer_id = null;
            vm.data.project_id = null;
        } else {
            vm.data.internal_project_id = null;
        }
    });

    $scope.$watch("lookup.project.all_project_with_customer", function (value) {
        if (value) {
            vm.data.customer_id = value.customer_id;
            vm.data.customer = {
                customer_name: value.customer_name
            };
            if($rootScope.lookup.project.all_project_with_customer.has_ledes === 1) {
                vm.project_has_ledes = true;
            }else{
                vm.project_has_ledes = false;
            }
            if($rootScope.lookup.project.all_project_with_customer.communication_language !== null) {
                vm.customerLanguage = $rootScope.lookup.project.all_project_with_customer.communication_language;
            }
            delete $rootScope.lookup.project.all_project_with_customer;
        }

    });
    $scope.$watch('[vm.data.date]', function (newValue, oldValue) {
        if ((typeof vm.data.date !== 'undefined') &&
            (typeof newValue[0] == 'string') && (typeof oldValue[0] == 'string') &&
            (typeof newValue[0] !== null) && (typeof oldValue[0] != null)
        ) {
            if (newValue[0].indexOf("/") && oldValue[0].indexOf("/")) {
                var newFilters = {};
                var oldFilters = {};
                newFilters.month = newValue[0].split('/')[1];
                newFilters.year = newValue[0].split('/')[2];

                oldFilters.month = oldValue[0].split('/')[1];
                oldFilters.year = oldValue[0].split('/')[2];

                if (typeof newFilters.month !== 'undefined' && typeof newFilters.year !== 'undefined' &&
                    typeof oldFilters.month !== 'undefined' && typeof oldFilters.year !== 'undefined'
                ) {
                    vm.rest.filter({
                        url: 'filter/accounting_period/search_accounting_period',
                        data: newFilters
                    }).then(function (data) {
                        vm.data.expense_ext.accounting_period_id = data.id;
                        vm.data.expense_ext.accounting_period.code = data.name;
                    });
                }
            }
        }
    }, true);

    vm.calculateValues = function () {
        // comentat pt ca pare reduntant - deja avem un watcher pe acest camp care stocheaza aceleasi info in vm

        /*	$scope.$watch('[vm.data.expense_type_id]', function (newValue, oldValue) {
                vm.expenseTypeList = function () {
                    var tmp = null;
                    $.ajax({
                        'async': false,
                        'type': "GET",
                        'dataType': 'json',
                        'url': "../../expense_type/" + vm.data.expense_type_id,
                        'success': function (response) {
                            tmp = response;
                        }
                    });
                    return tmp;
                }();
                vm.data.price = 0;
                if (vm.expenseTypeList.unit_currency && vm.expenseTypeList.unit_currency !== null ) {
                    vm.data.price = vm.expenseTypeList.unit_price;
                    // forteaza currency = valuta din tip cheltuiala, doar daca NU esti in decont, altfel, vezi in expense-edit-details.blade.php
                    if (!params.editFromEmployeeAccount) {
                        vm.data.currency = vm.expenseTypeList.unit_currency;
                    }
                }
                if (typeof vm.data.expense_ext == 'undefined') {
                    vm.data.expense_ext = {};
                }
                if (vm.expenseTypeList.account_expense) {
                    vm.data.expense_ext.accounting_ref_id = vm.expenseTypeList.account_expense.account_id;
                    vm.data.expense_ext.account = vm.expenseTypeList.account_expense.account_number + ' - ' + vm.expenseTypeList.account_expense.account_name;
                }
                if (vm.expenseTypeList.account_income) {
                    vm.data.expense_ext.income_account_id = vm.expenseTypeList.account_income.account_id;
                    vm.data.expense_ext.income_account = vm.expenseTypeList.account_income.account_number + ' - ' + vm.expenseTypeList.account_income.account_name;
                }
            }, true);*/

        $scope.$watch('vm.data.vat_type_id', function (value) {
            if (value && $rootScope.lookup && $rootScope.lookup.expense_vat_type) {
                vm.data.vat_percent = $rootScope.lookup.expense_vat_type.all_expense_vat_type.vat_percent;
                delete $rootScope.lookup.expense_vat_type.all_expense_vat_type;
            }
        });
        $scope.$watch('[vm.data.vat_percent, vm.data.total_amount, vm.data.quantity, vm.data.price, vm.data.expense_ext.allow_manual_input]', function (newValue, oldValue) {
            // daca permite introducere manuala a cheltuielii atunci nu mai completa automat valorile
            if(vm.data && vm.data.expense_ext && (parseInt(vm.data.expense_ext.allow_manual_input) != 1) && vm.data.expense_ext.allow_manual_input != true){
                if (vm.data.price && vm.data.quantity) {
                    vm.data.total_amount = parseFloat(vm.data.price) * parseFloat(vm.data.quantity);
                }
                //check for undefined because it can have value = 0
                if (vm.data.total_amount != undefined && vm.data.vat_percent != undefined) {
                    vm.data.base_amount = (vm.data.total_amount) / (vm.data.vat_percent / 100 + 1);
                    vm.data.vat_amount = parseFloat(vm.data.total_amount) - (vm.data.base_amount);
                    vm.data.vat_amount = vm.data.vat_amount.toFixed(2);
                    vm.data.base_amount = vm.data.base_amount.toFixed(2);

                    if (vm.data.total_amount == '') {
                        vm.data.base_amount = 0;
                    }
                    if (vm.data.vat_percent == '') {
                        vm.data.vat_amount = 0;
                    }
                } else {
                    //check for undefined because it can have value=0
                    if (vm.data.total_amount == undefined) {
                        vm.data.total_amount = 0;
                    }
                    if (vm.data.vat_percent == undefined) {
                        vm.data.vat_percent = 0;
                    }
                }
            }

        }, true);

    };

    vm.calculateValues();
    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);
        if (!postData.recipient) postData.recipient = "own_company";
        postData.is_billable = true;
        vm.exchangeRateError = 0;
        if (!postData.expense_ext) postData.expense_ext = {};

        if (!postData.expense_ext.status_id) postData.expense_ext.status_id = 1; //draft
        if (params.editFromEmployeeAccount) {
            if (params.employee_account.type_id) {
                if ([3, 4, 5, 6].indexOf(params.employee_account.type_id) > -1) postData.expense_ext.status_id = 4;
            }
            postData.expense_ext.employee_account_id = params.employee_account.employee_account_id;
            postData.internal_employee_account = params.employee_account.type_id;
            postData.source_expense_id = params.entity && params.duplicate ? params.entity.expense_id : null;
        }

        if (vm.project_has_ledes == 0) {
            postData.ledes_activity_code_id = null;
        }

        if(postData.activity_budget && postData.activity_budget.activity_budget_id){
            postData.activity_budget.activity_budget_id = postData.activity_budget_id;
        }

        return postData;

    };

    vm.saveData = function (saveType) {
        vm.saveCallback = function (response) {
            this.close({entity: response.entity, index: response.index, saveType: saveType});
        };
        return vm.save();
    };

    return vm;
});
