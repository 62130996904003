kv.controller('efacturaReceivedViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal,cmdate, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'efactura_received';
    vm.entityType = 'efactura_received';
    vm.groups = {};

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    vm.entityId = page_entity_id;
    vm.commentsConfig = {
        entity_type: vm.entity
    };

    vm.goBack = function () {
        $state.go("app.efactura_received");
    };

    vm.getEfacturaReceivedDetails = function getEfacturaReceivedDetails() {
        var REST = resource.init('efactura_received/list');
        REST.get({
            params: {
                efactura_received_id: page_entity_id
            }
        }).then(function (data) {
            vm.efactura_received = data.data[0].details;
        });
    };
    vm.getEfacturaReceivedDetails();

    vm.getEfacturaReceivedExpenses = function getEfacturaReceivedExpenses() {
        var REST = resource.init('expense/list');
        REST.get({
            params: {
                efactura_received_id: page_entity_id
            }
        }).then(function (data) {
            vm.expenses = data.data;
        });
    };

    vm.getEfacturaReceivedExpenses();

    vm.getQueryParam = function (param, param_value) {
        return "&" + param + "=" + param_value;
    };

    vm.addExpenseFromEfacturaReceived = function(tenant_fiscal_entity_id, tenant_fiscal_entity_name, invoice_date, total_amount, currency, invoice_series, customer_fiscal_entity_id, customer_fiscal_entity_name, invoice_id, vat_code, description){
        let rest = vm.getInjection('resource').init('efactura_received');
        let action = 'add_expense';
        rest.post({
            url: 'efactura_received/' + page_entity_id + '/actions/' + action,
        })
            .then((response) => {
                if(response.status) {
                    window.location.href = vm.API.URL + response.data;
                }
            });
    };

    /**
     * mark as confidential
     */
    vm.markSupplierAsConfidential = function (customer_fiscal_entity_id) {
        KApp.block('.changeStatus');

        let rest = vm.getInjection('resource').init('customer_fiscal_entity');
        rest.post({
            url: 'customer_fiscal_entity/' + customer_fiscal_entity_id + '/actions/mark_confidential',
        })
            .then((response) => {
                toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
                if (!response.status) vm.alert('ALERT', vm.trans('Error setting confidential status'));
                KApp.unblock('.changeStatus');
                window.location.href = "/efactura_received/" + page_entity_id;
            })
            .catch(err => {
                KApp.unblock('.changeStatus');
                console.log('Error setting confidential status');
                console.log(err); // bum related error
                window.location.href = "/efactura_received/" + page_entity_id;
            });
    };

    vm.markAsProcessedOrUnprocessed = function (efactura_received_id, is_processed) {
        //page_entity_id
        KApp.block('.changeStatus');
        var action = '';
        let rest = vm.getInjection('resource').init('efactura_received');
        if(is_processed == 1){
             action = 'mark_as_unprocessed';
        } else {
             action = 'mark_as_processed';
        }
        rest.post({
            url: 'efactura_received/' + efactura_received_id + '/actions/' + action,
        })
        .then((response) => {
            toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
            if (!response.status) vm.alert('ALERT', vm.trans('Error setting processed status'));
            KApp.unblock('.changeStatus');
            vm.entity.processed = true;
            // wait 500 ms
            setTimeout(() => {
                window.location.href = "/efactura_received/" + page_entity_id;
            }, 500);
        })
        .catch(err => {
            KApp.unblock('.changeStatus');
            console.log('Error setting processed status');
            console.log(err); // bum related error
            if (response.message) vm.alert('ALERT', response.message);
            // window.location.href = "/efactura_received/" + page_entity_id;
        });
    }

    return vm;
});
