kv.controller('courseMemberListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'course_attendee_id';
	vm.entity = 'course_attendee';
	vm.count = {
		total: 0,
		showing: 0
	};

	let url_segments = window.location.pathname.split('/');
	vm.courseId = url_segments[2];

	vm.deleteMember = function deleteEntity($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then((response) => {
			if (response !== 'ok') return;
			let REST = resource.init("course_attendee");

			REST.delete({
				url: "course/:entity_id/course_attendee/:relatedId",
				params: {
					entity_id: vm.courseId,
					relatedId: vm.infinitScroll.items[$index]["course_attendee_id"],
				}
			}).then(function (data) {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	vm.addMember = function (people_type) {
		vm.openModal({
			templateUrl: "people-list",
			controller: 'addMemberListCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				bladeParams: function () {
					return vm.bladeParams;
				},
				data: function () {
					$(document).find("html").css("overflow", "hidden");
					return {
						people_type: people_type,
						entity_id: vm.courseId,
						entity: "course",
						entity_related: "course_attendee",
					};
				}
			}
		}).then((result) => {
			if (result.length) vm.infinitScroll.search.refresh++;
		});
	};


	return vm;
});

