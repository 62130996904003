kv.controller('DashboardWidgetChartsCtrl', function ($element, $attrs, $scope, $injector) {
	if (!$attrs.widgetName) console.error("controller element must have widget_name attribute");
	let vm = {};
	let renderChart = function (data, id) {
		// console.log("render", data, id);
		if (data[0] && data[0].data && data[0].data[0]) {
			this.chart[id](data, id);
		} else {
			vm.error[id] = vm.trans("LANG.NO_DATA");
		}
		vm.loading[id] = false;
	};
	let getChartId = function (id) {
		let chartId = vm.bladeParams.tabId + "-" + $attrs.widgetName + "-" + id;
		return chartId;
	};
	let chartsConfig = {
		'template': {
			renderChart: function (data, id) {
				vm.loading[1] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'lawyer-efficiency': {
			// monthly effort, stacked
			renderChart: function (data, id) {
				if (typeof (data.chart1) !== 'undefined' && data.chart1.length !== 0) {
					this.chart[1](data, 1);
				} else {
					vm.error[1] = vm.trans("NO_DATA");
				}

				if (typeof (data.chart2) !== 'undefined' && data.chart2.length !== 0) {
					this.chart[2](data, 2);
				} else {
					vm.error[2] = vm.trans("NO_DATA");
				}
				vm.loading[id] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => {
										this.reflow();
									});
								}
							},
							spacing: [5, 5, 7, 5]
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						xAxis: {
							categories: data.chart1.categories
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							stackLabels: {
								enabled: false,
								style: {
									fontWeight: 'bold',
									color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
								}
							}
						},
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							floating: false,
							borderWidth: 1,
							shadow: false
						},
						tooltip: {
							shared: true,
							valueSuffix: " ore",
							headerFormat: '<b>{point.x}</b><br/>',
							pointFormat: '{series.name}: {point.y}<br />'
						},
						plotOptions: {
							column: {
								stacking: 'normal',
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.chart1.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = {
						chart: {
							type: 'line',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: data.chart2.categories,
							crosshair: true
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y}%</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							},
							line: {
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.chart2.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'managing-chart-monthly-hours-and-efficiency': {
			renderChart: function (data, id) {
				if (typeof (data.chart1) !== 'undefined' && data.chart1.length !== 0) {
					this.chart[1](data, 1);
				} else {
					vm.error[1] = vm.trans("NO_DATA");
				}

				if (typeof (data.chart2) !== 'undefined' && data.chart2.length !== 0) {
					this.chart[2](data, 2);
				} else {
					vm.error[2] = vm.trans("NO_DATA");
				}
				vm.loading[id] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => {
										this.reflow();
									});
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						xAxis: {
							categories: data.chart1.categories
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							stackLabels: {
								enabled: false,
								style: {
									fontWeight: 'bold',
									color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
								}
							}
						},
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							floating: false,
							borderWidth: 1,
							shadow: false
						},
						tooltip: {
							shared: true,
							valueSuffix: " ",
							headerFormat: '<b>{point.x}</b><br/>',
							pointFormat: '{series.name}: {point.y}<br />'
						},
						plotOptions: {
							column: {
								stacking: 'normal',
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.chart1.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = {
						chart: {
							type: 'line',
							events: {
								load: function () {
									setTimeout(() => {
										this.reflow();
									});
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: data.chart2.categories,
							crosshair: true
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y}%</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							},
							line: {
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.chart2.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'managing-chart-due-invoices': {
			renderChart: function (data, id) {
				if (typeof (data.data) !== 'undefined' && data.data.length !== 0) {
					if (typeof (data.data) !== 'undefined' && data.data.length !== 0) {
						this.chart[1](data, 1);
					} else {
						vm.error[1] = vm.trans("NO_DATA");
					}
				}

				if (typeof (data[0]) !== 'undefined' && data[0].length !== 0) {
					if (typeof data[0].data !== 'undefined' && data[0].data.length !== 0) {
						this.chart[2](data, 2);
					} else {
						// $('#{{ $tab1_identifier }}').text('{{ trans('LANG.NO_DATA') }}');
						vm.error[2] = vm.trans('LANG.NO_DATA');
					}
				}

				vm.loading[id] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'spline',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						xAxis: {
							categories: data.xAxis.categories,
							crosshair: true
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y} ' + data.suffix + '</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							},
							line: {
								dataLabels: {
									enabled: false
								}
							}
						},
						series: [{
							lineWidth: 4,
							name: data.name,
							data: data.data,
							color: '#769EFE'
						}]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						plotOptions:
							{
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels:
										{
											enabled: true,
											style:
												{
													color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
												}
										},
									showInLegend: false
								}
							},
						series: [{
							colorByPoint: true,
							innerSize: '60%',
							data: data[0]['data']
						}]
					};
					if (vm.bladeParams.percentageOnly == 0) {
						config.tooltip = {
							headerFormat: '',
							pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
						};
					} else if (vm.bladeParams.percentageOnly == 1) {
						config.tooltip = {
							headerFormat: '',
							pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
						};
					}

					// hide dataLabels when resolution is xxs (phone)
					if ($(window).width() < 480) {
						config.plotOptions.pie.dataLabels.enabled = false
					}

					config.exporting = false;
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'managing-practice-share': {
			renderChart: function (data, id) {
				// console.log('render', data, id);
				if (id == 1) {
					if (typeof (data[0].data) !== 'undefined' && data[0].data.length !== 0) {
						this.chart[1](data, 1);
					} else {
						vm.error[1] = vm.trans("NO_DATA");
					}
				} else if (id == 2) {
					if (typeof (data[0].data) !== 'undefined' && data[0].data.length !== 0) {
						this.chart[2](data, 2);
					} else {
						vm.error[2] = vm.trans("NO_DATA");
					}
				}
				vm.loading[id] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									this.reflow();
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}, {
										separator: true
									}, {
										text: vm.trans('VIEW_DETAILS'),
										onclick: function () {
											window.open(vm.tab1_url, '_blank');
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						tooltip: {
							headerFormat: '',
							pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
						},
						plotOptions: {
							pie: {
								allowPointSelect: true,
								cursor: 'pointer',
								innerSize: '60%',
								dataLabels: {
									enabled: true,
									style: {
										color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
									}
								},
								showInLegend: false
							}
						},
						series: [data[0]]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									this.reflow();
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}, {
										separator: true
									}, {
										text: vm.trans('VIEW_DETAILS'),
										onclick: function () {
											window.open(vm.tab2_url, '_blank');
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						tooltip: {
							headerFormat: '',
							pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
						},
						plotOptions: {
							pie: {
								allowPointSelect: true,
								cursor: 'pointer',
								innerSize: '60%',
								dataLabels: {
									enabled: true,
									style: {
										color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
									}
								},
								showInLegend: false
							}
						},
						series: [data[0]]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'managing-chart-invoices-by-year-month': {
			renderChart: function (data, id) {
				if (typeof (data['chart1']) !== 'undefined' && data['chart1'].length !== 0) {
					this.chart[1](data, 1);
				} else {
					vm.error[1] = vm.trans("NO_DATA");
				}

				if (typeof (data['chart2']) !== 'undefined' && data['chart2'].length !== 0) {
					this.chart[2](data, 2);
				} else {
					vm.error[2] = vm.trans("NO_DATA");
				}
				vm.loading[id] = false;
			},
			chart: {
				// invoices evolution
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => {
										this.reflow();
									});
								}
							},
						},
						credits: {
							enabled: false
						},
						title: {
							text: ''
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: data.xAxis.categories,
							crosshair: true
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							}
						},
						tooltip: {
							shared: true,
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y: ,.2f} ' + data.suffix + '</b></td></tr>',
							footerFormat: '</table>',
							valueDecimals: 2,
							useHTML: true
						},
						plotOptions: {
							column: {
								// pointPadding: 0.2,
								borderWidth: 0
							},
							line: {
								dataLabels: {
									enabled: true,
									formatter: function () {
										return Highcharts.numberFormat(this.y,2);
									}
								},
								states: {
									hover: {
										enabled: true,
										lineWidth: 0.001
									}
								},
								lineWidth: 0,
								visible: false //make the lines disappear
							}
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						series: [
							{
								name: data.chart1.values[1].name,
								data: data.chart1.values[1].data,
								color : '#769EFE'
							},
							{
								name: data.chart1.values[0].name,
								data: data.chart1.values[0].data,
								color : '#ccc'
							},
							{
								name: data.chart1.values[3].name,
								data: data.chart1.values[3].data,
								color: Highcharts.getOptions().colors[5],
								visible: false,
								lineWidth: 4,
							},
							{
								name: data.chart1.values[2].name,
								data: data.chart1.values[2].data,
								color: '#bedd8d',
								visible: false,
								lineWidth: 4,
							}
							// {
							// 	type: 'spline',
							// 	name: data.chart1.values[4].name,
							// 	marker: {
							// 		symbol: 'triangle',
							// 		lineWidth: 1,
							// 		lineColor: "#444",
							// 		radius: 5
							// 	},
							// 	visible: false,
							// 	data: data.chart1.values[4].data
							// }
						]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				// payments evolution
				2: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => {
										this.reflow();
									});
								}
							},
						},
						credits: {
							enabled: false
						},
						title: {
							text: ''
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: data.xAxis.categories,
							crosshair: true
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							}
						},
						tooltip: {
							shared: true,
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y: ,.2f} ' + data.suffix + '</b></td></tr>',
							footerFormat: '</table>',
							valueDecimals: 2,
							useHTML: true
						},
						plotOptions: {
							column: {
								borderWidth: 0
							},
							line: {
								dataLabels: {
									enabled: true,
									formatter: function () {
										return Highcharts.numberFormat(this.y,2);
									}
								},
								states: {
									hover: {
										enabled: true,
										lineWidth: 0.001
									}
								},
								lineWidth: 0,
								visible: false //make the lines disappear
							}
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						series: [
							{
								name: data.chart2.values[1].name,
								data: data.chart2.values[1].data,
								color : '#769EFE'
							},
							{
								name: data.chart2.values[0].name,
								data: data.chart2.values[0].data,
								color : '#ccc'
							},
							{
								name: data.chart2.values[3].name,
								data: data.chart2.values[3].data,
								color : '#76d3fe',
								visible: false
							},
							{
								name: data.chart2.values[2].name,
								data: data.chart2.values[2].data,
								color: '#bedd8d',
								visible: false
							}
							// {
							// 	type: 'line',
							// 	name: data.chart2.values[4].name,
							// 	color: '#fb8a9e',
							// 	marker: {
							// 		symbol: 'circle',
							// 		lineWidth: 1,
							// 		lineColor: "#444",
							// 		radius: 5
							// 	},
							// 	data: data.chart2.values[4].data
							// }
						]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'managing-chart-dso-by-year-month': {
			renderChart: function (data, id) {
				if (typeof (data[0]) !== 'undefined' && data[0].value.length !== 0) {
					this.chart(data, 1);
				} else {
					vm.error[1] = vm.trans("NO_DATA");
				}
				vm.loading[id] = false;
			},
			chart: function (data, id) {
				let config = {
					chart: {
						type: 'spline',
						events: {
							load: function () {
								setTimeout(() => {
									this.reflow();
								});
							}
						}
					},
					credits: {
						enabled: false
					},
					navigation: {
						buttonOptions: {
							theme: {
								zIndex: 9999
							}
						}
					},
					exporting: {
						buttons: {
							contextButton: {
								menuItems: [{
									text: 'Export to PDF',
									onclick: function () {
										this.exportChart({
											type: 'application/pdf'
										});
									},
									separator: false
								}, {
									text: 'Export to JPG',
									onclick: function () {
										this.exportChart({
											type: 'image/jpeg'
										});
									},
									separator: false
								}, {
									text: 'Export to PNG',
									onclick: function () {
										this.exportChart({
											type: 'image/png'
										});
									},
									separator: false
								}]
							}
						}
					},
					title: {
						text: null
					},
					subtitle: {
						text: ''
					},
					xAxis: {
						categories: data[0].xAxis.categories,
						// crosshair: true
					},
					yAxis: {
						min: 0,
						title: {
							text: 'days'
						}
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
							'<td style="padding:0"><b>{point.y} ' + data[0]
								.suffix + '</b></td></tr>',
						footerFormat: '</table>',
						// shared: true,
						useHTML: true
					},
					plotOptions: {
						// column: {
						// 	pointPadding: 0.2,
						// 	borderWidth: 0
						// },
						// line: {
						// 	dataLabels: {
						// 		enabled: false
						// 	}
						// }
					},
					series: [{
						lineWidth: 4,
						name: data[0].name,
						data: data[0].value,
						color: '#f47272'
					}]
				};
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);
			}
		},
		'practice-chart-top-customers': {
			renderChart: renderChart,
			chart:
				{
					1: function (data, id) {
						let config = {
							chart: {
								plotBackgroundColor: null,
								plotBorderWidth: null,
								plotShadow: false,
								type: 'pie',
								events: {
									load: function () {
										this.reflow();
									}
								},
								height: 400,
							},
							credits: {
								enabled: false
							},
							navigation: {
								buttonOptions: {
									theme: {
										zIndex: 9999
									}
								}
							},
							exporting: {
								buttons: {
									contextButton: {
										menuItems: [{
											text: 'Export to PDF',
											onclick: function () {
												this.exportChart({
													type: 'application/pdf'
												});
											},
											separator: false
										}, {
											text: 'Export to JPG',
											onclick: function () {
												this.exportChart({
													type: 'image/jpeg'
												});
											},
											separator: false
										}, {
											text: 'Export to PNG',
											onclick: function () {
												this.exportChart({
													type: 'image/png'
												});
											},
											separator: false
										}, {
											separator: true
										}, {
											text: vm.trans("VIEW_DETAILS"),
											onclick: function () {
												window.open(vm.tab1_url, '_blank');
											},
											separator: false
										}]
									}
								}
							},
							title: {
								text: ''
							},
							plotOptions:
								{
									pie: {
										allowPointSelect: true,
										cursor:'pointer',
										innerSize: '60%',
										dataLabels:
											{
												enabled: true,
												style:
													{
														color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
													}
											},
										showInLegend: false
									}
								},
							series: [data[0]]
						};
						if (vm.bladeParams.percentageOnly == 0) {
							config.tooltip = {
								headerFormat: '',
								pointFormat: '{point.name}: <b>{point.y} ' + data[0].suffix + ' ({point.percentage:.1f} %) </b>'
							};
						} else if (vm.bladeParams.percentageOnly == 1) {
							config.tooltip = {
								headerFormat: '',
								pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
							};
						}
						// hide dataLabels when resolution is xxs (phone)
						if ($(window).width() < 480) {
							config.plotOptions.pie.dataLabels.enabled = false;
						}
						let chartId = getChartId(id);
						$('#' + chartId).highcharts(config);
					},
					2: function (data, id) {

						let config = {
							chart: {
								plotBackgroundColor: null,
								plotBorderWidth: null,
								plotShadow: false,
								type: 'pie',
								events: {
									load: function () {
										this.reflow();
									}
								},
								height: 400,
							},
							credits: {
								enabled: false
							},
							navigation: {
								buttonOptions: {
									theme: {
										zIndex: 9999
									}
								}
							},
							exporting: {
								buttons: {
									contextButton: {
										menuItems: [{
											text: 'Export to PDF',
											onclick: function () {
												this.exportChart({
													type: 'application/pdf'
												});
											},
											separator: false
										}, {
											text: 'Export to JPG',
											onclick: function () {
												this.exportChart({
													type: 'image/jpeg'
												});
											},
											separator: false
										}, {
											text: 'Export to PNG',
											onclick: function () {
												this.exportChart({
													type: 'image/png'
												});
											},
											separator: false
										}, {
											separator: true
										}, {
											text: vm.trans("VIEW_DETAILS"),
											onclick: function () {
												window.open(vm.tab2_url, '_blank');
											},
											separator: false
										}]
									}
								}
							},
							title: {
								text: ''
							},
							plotOptions:
								{
									pie: {
										allowPointSelect: true,
										cursor: 'pointer',
										innerSize: '60%',
										dataLabels:
											{
												enabled: true,
												style:
													{
														color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
													}
											}
										,
										showInLegend: false
									}
								},
							series: [data[0]]
						};
						if (vm.bladeParams.percentageOnly == 0) {
							config.tooltip = {
								headerFormat: '',
								pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
							};
						} else if (vm.bladeParams.percentageOnly == 1) {
							config.tooltip = {
								headerFormat: '',
								pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
							};
						}

						// hide dataLabels when resolution is xxs (phone)
						if ($(window).width() < 480) {
							config.plotOptions.pie.dataLabels.enabled = false;
						}

						let chartId = getChartId(id);
						$('#' + chartId).highcharts(config);
						vm.loading[id] = false;
					}
				}
		},
		'practice-chart-monthly-hours': {
			renderChart: function (data, id) {
				// console.log("render", data, id);
				if (typeof (data) !== 'undefined' && data.length !== 0) {
					this.chart[id](data, 1);
				} else {
					vm.error[id] = vm.trans('LANG.NO_DATA');
				}
				vm.loading[id] = false;
			}
			,
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						xAxis: {
							categories: data.categories
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							stackLabels: {
								enabled: false,
								style: {
									fontWeight: 'bold',
									color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
								}
							}
						},
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							floating: false,
							borderWidth: 1,
							shadow: false
						},
						tooltip: {
							shared: true,
							valueSuffix: " " + vm.bladeParams.suffix,
							headerFormat: '<b>{point.x}</b><br/>',
							pointFormat: '{series.name}: {point.y}<br />'
						},
						plotOptions: {
							column: {
								stacking: 'normal',
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'practice-chart-invoiced-effort': {
			renderChart: function (data, id) {
				// console.log("render", data, id);
				if (typeof (data) !== 'undefined' && data.length !== 0) {
					this.chart[id](data, 1);
				} else {
					vm.error[id] = vm.trans('LANG.NO_DATA');
				}
				vm.loading[id] = false;
			}
			,
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						xAxis: {
							categories: data.categories
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							stackLabels: {
								enabled: false,
								style: {
									fontWeight: 'bold',
									color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
								}
							}
						},
						legend: {
							align: 'center',
							verticalAlign: 'bottom',
							floating: false,
							borderWidth: 1,
							shadow: false
						},
						tooltip: {
							shared: true,
							valueSuffix: " " + vm.bladeParams.suffix,
							headerFormat: '<b>{point.x}</b><br/>',
							pointFormat: '{series.name}: {point.y}<br />'
						},
						plotOptions: {
							column: {
								dataLabels: {
									enabled: false
								}
							}
						},
						series: data.series
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				}
			}
		},
		'controller-chart-due-invoices': {
			renderChart: function (data, id) {
				if (typeof (data[0]) !== 'undefined' && data[0].length !== 0) {
					if (typeof data[0].data !== 'undefined' && data[0].data.length !== 0) {
						this.chart[1](data, 1);
					} else {
						// $('#{{ $tab1_identifier }}').text('{{ trans('LANG.NO_DATA') }}');
						vm.error[1] = vm.trans('LANG.NO_DATA');
					}
				}
				vm.loading[1] = false;
			},
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						plotOptions:
							{
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels:
										{
											enabled: true,
											style:
												{
													color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
												}
										},
									showInLegend: false
								}
							},
						series: [{
							colorByPoint: true,
							data: data[0]['data']
						}]
					};
					if (vm.bladeParams.percentageOnly == 0) {
						config.tooltip = {
							headerFormat: '',
							pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
						};
					} else if (vm.bladeParams.percentageOnly == 1) {
						config.tooltip = {
							headerFormat: '',
							pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
						};
					}

					// hide dataLabels when resolution is xxs (phone)
					if ($(window).width() < 480) {
						config.plotOptions.pie.dataLabels.enabled = false
					}

					config.exporting = false;
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
			}
		},
		'litigation-chart-hearings': {
			renderChart: function (data, id) {
				if (typeof (data) !== 'undefined' && data.length !== 0 && data.dataLocation) {
					if (typeof data.dataLocation !== 'undefined' && data.dataLocation.length !== 0) {
						this.chart[1](data, 1);
					} else {
						vm.error[1] = vm.trans('LANG.NO_DATA');
					}
				}
				vm.loading[1] = false;

				if (typeof (data) !== 'undefined' && data.length !== 0 && data.dataCustomer) {
					if (typeof data.dataCustomer !== 'undefined' && data.dataCustomer.length !== 0) {
						this.chart[2](data, 2);
					} else {
						vm.error[2] = vm.trans('LANG.NO_DATA');
					}
				}
				vm.loading[2] = false;

				if (typeof (data) !== 'undefined' && data.length !== 0 && data.dataLawyer) {
					if (typeof data.dataLawyer !== 'undefined' && data.dataLawyer.length !== 0) {
						this.chart[3](data, 3);
					} else {
						vm.error[3] = vm.trans('LANG.NO_DATA');
					}
				}
				vm.loading[3] = false;
			},
			chart: {
				// by location
				1: function (data, id) {
					var new_data_location = [];
					angular.forEach(data['dataLocation'], function(value, key) {
						this.push([value[0] , parseFloat(value[1])]);
					}, new_data_location);
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						tooltip: {
							pointFormat: '<b>{point.percentage:.1f}%</b>'
						},
						plotOptions:
							{
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels:
										{
											enabled: true,
										},
									showInLegend: false
								}
							},
						series: [{
							name: '',
							colorByPoint: true,
							innerSize: '60%',
							data: new_data_location
						}]
					};
					

					// hide dataLabels when resolution is xxs (phone)
					if ($(window).width() < 480) {
						config.plotOptions.pie.dataLabels.enabled = false
					}

					config.exporting = false;
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				// by customer
				2: function (data, id) {
					var new_data_customer = [];
					angular.forEach(data['dataCustomer'], function(value, key) {
						this.push([value[0] , parseFloat(value[1])]);
					}, new_data_customer);
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						tooltip: {
							pointFormat: '<b>{point.percentage:.1f}%</b>'
						},
						plotOptions:
							{
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels:
										{
											enabled: true,
										},
									showInLegend: false
								}
							},
						series: [{
							name: '',
							colorByPoint: true,
							innerSize: '60%',
							data: new_data_customer
						}]
					};
					// if (vm.bladeParams.percentageOnly == 0) {
					// 	config.tooltip = {
					// 		headerFormat: '',
					// 		pointFormat: '{point.name}: <b>{point.y} ' + data[0]['suffix'] + ' ({point.percentage:.1f} %) </b>'
					// 	};
					// } else if (vm.bladeParams.percentageOnly == 1) {
					// 	config.tooltip = {
					// 		headerFormat: '',
					// 		pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
					// 	};
					// }

					// hide dataLabels when resolution is xxs (phone)
					if ($(window).width() < 480) {
						config.plotOptions.pie.dataLabels.enabled = false
					}

					config.exporting = false;
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				// by lawyer
				3: function (data, id) {
					var new_data_lawyer = [];
					angular.forEach(data['dataLawyer'], function(value, key) {
						this.push([value[0] , parseFloat(value[1])]);
					}, new_data_lawyer);
					let config = {
						chart: {
							plotBackgroundColor: null,
							plotBorderWidth: null,
							plotShadow: false,
							type: 'pie',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							},
							height: 400,
						},
						credits: {
							enabled: false
						},
						navigation: {
							buttonOptions: {
								theme: {
									zIndex: 9999
								}
							}
						},
						exporting: {
							buttons: {
								contextButton: {
									menuItems: [{
										text: 'Export to PDF',
										onclick: function () {
											this.exportChart({
												type: 'application/pdf'
											});
										},
										separator: false
									}, {
										text: 'Export to JPG',
										onclick: function () {
											this.exportChart({
												type: 'image/jpeg'
											});
										},
										separator: false
									}, {
										text: 'Export to PNG',
										onclick: function () {
											this.exportChart({
												type: 'image/png'
											});
										},
										separator: false
									}]
								}
							}
						},
						title: {
							text: ''
						},
						tooltip: {
							pointFormat: '<b>{point.percentage:.1f}%</b>'
						},
						plotOptions:
							{
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels:
										{
											enabled: true,
										},
									showInLegend: false
								}
							},
						series: [{
							name: '',
							colorByPoint: true,
							innerSize: '60%',
							data: new_data_lawyer
						}]
					};

					// hide dataLabels when resolution is xxs (phone)
					if ($(window).width() < 480) {
						config.plotOptions.pie.dataLabels.enabled = false
					}

					config.exporting = false;
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
			}
		},
		'hearings-evolution': {
			renderChart: function (data, id) {
				if (typeof (data) !== 'undefined' && data.length !== 0) {
					this.chart(data, 1);
				} else {
					vm.error[1] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[id] = false;
			}
			,
			chart: function (data, id) {
				var new_data_evolution = [];
				angular.forEach(data.data.values, function(value, key) {
					this.push(parseFloat(value));
				}, new_data_evolution);
				let config = {
					chart: {
			        	type: 'column'
				    },
				    exporting: {
						buttons: {
							contextButton: {
								menuItems: [{
									text: 'Export to PDF',
									onclick: function () {
										this.exportChart({
											type: 'application/pdf'
										});
									},
									separator: false
								}, {
									text: 'Export to JPG',
									onclick: function () {
										this.exportChart({
											type: 'image/jpeg'
										});
									},
									separator: false
								}, {
									text: 'Export to PNG',
									onclick: function () {
										this.exportChart({
											type: 'image/png'
										});
									},
									separator: false
								}]
							}
						}
					},
				    title: {
				        text: ''
				    },
				    xAxis: {
				        categories: data.data.categories,
				        crosshair: true
				    },
				    yAxis: {
				        min: 0,
				        title: {
				            text: ''
				        }
				    },
					tooltip: {
						pointFormat: '<b>{point.y} ' + vm.trans('HEARINGS') + '</b>'
					},
				    plotOptions: {
				        column: {
				            pointPadding: 0.2,
				            borderWidth: 0,
							dataLabels:
								{
									enabled: false,
								},
							showInLegend: false
				        }
				    },
				    series: [{
				        name: '',
				        data: new_data_evolution


				    }]
				};
				// hide dataLabels when resolution is xxs (phone)
				if ($(window).width() < 480) {
					config.plotOptions.pie.dataLabels.enabled = false
				}

				config.exporting = false;
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);
			}
		},
		'marketing-offers-by-status': {
			renderChart: function (data, id) {
				if (data[0] && (data[0].SUBMITTED || data[0].LOST || data[0].WON || data[0].IN_PREPARATION || data[0].ABANDONED || data[0].CONFLICTED || data[0].SUSPENDED)) {
					this.chart(data, 1);
				} else {
					vm.error[1] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[id] = false;
			}
			,
			chart: function (data, id) {
				let config = {
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'column',
						events: {
							load: function () {
								setTimeout(() => this.reflow());
							}
						},
					},
					title: {
						text: null
					},
					credits: {
						enabled: false
					},
					subtitle: {
						text: null
					},
					xAxis: {
						categories: data[0].categories,
						tickmarkPlacement: 'on',
						title: {
							enabled: false
						}
					},
					yAxis: {
						title: {
							text: null
						},
						labels: {
							formatter: function () {
								return this.value;
							}
						}
					},
					tooltip: {
						shared: true,
						valueSuffix: ' ' + vm.trans("LANG.OFFERS").toLowerCase()
					},
					plotOptions: {
						column: {
							stacking: 'normal',
							dataLabels: {
								enabled: false
							}
						}
					},
					series: [
					{
						name: vm.trans("LANG.IN_PREPARATION"),
						data: data[0]['IN_PREPARATION'],
						color: '#78d2d2'
					}, {
						name: vm.trans("LANG.ABANDONED"),
						data: data[0]['ABANDONED'],
						color: '#ffc57c'
					}, {
						name: vm.trans("LANG.CONFLICTED"),
						data: data[0]['CONFLICTED'],
						color: '#b684cb'
					}, {
						name: vm.trans("LANG.SUSPENDED"),
						data: data[0]['SUSPENDED'],
						color: '#ccc'
					}, {
						name: vm.trans("LANG.SUBMITTED"),
						data: data[0]['SUBMITTED'],
						color: '#769EFE'
					}, {
						name: vm.trans("LANG.LOST"),
						data: data[0]['LOST'],
						color: '#f47272'
					}, {
						name: vm.trans("LANG.WON"),
						data: data[0]['WON'],
						color: '#bedd8d'
					}]
				};
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);
			}
		},
		'marketing-chart-offers-by-year-month': {
			renderChart: function (data, id) {
				if (data[0] && (data[0].offers_count || data[0].won_count)) {
					this.chart(data, 1);
				} else {
					vm.error[1] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[1] = false;
			}
			,
			chart: function (data, id) {
				let config = {
					chart: {
						zoomType: 'xy',
						events: {
							load: function () {
								setTimeout(() => this.reflow());
							}
						}
					},
					credits: {
						enabled: false
					},
					title: {
						text: null
					},
					subtitle: {
						text: null
					},
					xAxis: {
						categories: data[0].categories,
						tickmarkPlacement: 'on',
						title: {
							enabled: false
						}
					},
					yAxis: [{ // Primary yAxis
						labels: {
							style: {
								color: Highcharts.getOptions().colors[1]
							}
						},
						title: {
							text: null,
							style: {
								color: Highcharts.getOptions().colors[1]
							}
						}
					}, { // Secondary yAxis
						title: {
							text: null,
							style: {
								color: Highcharts.getOptions().colors[0]
							}
						},
						labels: {
							style: {
								color: Highcharts.getOptions().colors[0]
							}
						},
						opposite: true
					}],
					tooltip: {
						shared: true
					},
					legend: {
						enabled: true
					},
					series: [{
						name: vm.trans("LANG.OFFERS"),
						type: 'column',
						data: data[0]['offers_count'],
						yAxis: 1

					}, {
						name: vm.trans("LANG.DASHBOARD_WON"),
						type: 'spline',
						data: data[0]['won_count'],
						tooltip: {
							valueSuffix: ' %'
						}
					}]
				};
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);

			}
		},
		'bd-chart-new-business': {
			renderChart: function (data, id) {
				// data[0] - evolution & data[1] =totals
				if (typeof (data) !== 'undefined' && data.length !== 0) {
					this.chart[1](data, 1);
					this.chart[2](data, 2);
				} else {
					vm.error[1] = vm.trans("NO_DATA");
					vm.error[2] = vm.trans("NO_DATA");
				}
				vm.loading[1] = false;
			}
			,
			chart: {
				1: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						title: {
							text: null
						},
						subtitle: {
							text: null
						},
						xAxis: {
							categories: data.categories,
							stacked: false
						},
						yAxis: [
							{
								title: {
									text: null,
								},
							}, 
						],
						tooltip: {
							shared: true
						},
						plotOptions: {
							column: {
								// pointPadding: 0.2,
								borderWidth: 0
							},
							line: {
								states: {
									hover: {
										enabled: true,
										lineWidth: 0.001
									}
								},
								lineWidth: 0,
								visible: false //make the lines disappear
							}
						},
						legend: {
							shadow: false
						},
						series: [{
							name: data['series'][0]['name'],
							data: data['series'][0]['data']
						}, {
							name: data['series'][1]['name'],
							data: data['series'][1]['data'],
							// yAxis: 1
						}
						// fara dosare momentan
						// }, {
						// 	type: 'column',
						// 	name: data['series'][2]['name'],
						// 	data: data['series'][2]['data']
						// }
						]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = {
						chart: {
							type: 'column',
							events: {
								load: function () {
									setTimeout(() => this.reflow());
								}
							}
						},
						credits: {
							enabled: false
						},
						title: {
							text: null
						},
						subtitle: {
							text: null
						},
						xAxis: {
							categories: data.categories,
							stacked: true
						},
						yAxis: [
							{
								title: {
									text: null,
								},
							}, 
						],
						plotOptions: {
							column: {
								stacking: 'normal',
								borderWidth: 0
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y} ' + data.suffix + '</b> ({point.percentage:.1f}%)</td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						legend: {
							shadow: false
						},
						series: [
							{
								type: 'column',
								name: data['series'][3]['name'],
								data: data['series'][3]['data'],
								color: '#bedd8d'
							}, {
								type: 'column',
								name: data['series'][4]['name'],
								data: data['series'][4]['data'],
								color: '#769EFE'
							}, {
								type: 'column',
								name: data['series'][5]['name'],
								data: data['series'][5]['data'],
								color: '#f47272'
							},{
							    type: 'pie',
							    name: 'Total',
								innerSize: '60%',
								tooltip: {
							        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
							    },
							    data: [{
							        name: data['series'][3]['name'],
							        y: data.totals[0],
							        color: '#bedd8d'
							    }, {
							        name: data['series'][4]['name'],
							        y: data.totals[1],
							        color: '#769EFE'
							    }, {
							        name: data['series'][5]['name'],
							        y: data.totals[2],
							        color: '#f47272'
							    }],
							    center: [50, 30],
							    size: 120,
							    showInLegend: false,
							    dataLabels: {
							        enabled: false
							    }
							}
						]
					};
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},

			}
		},
		'marketing-chart-offers-by-coordinator': {
			renderChart: renderChart,
			chart:
				{
					1: function (data, id) {
						let config = {
							chart: {
								plotBackgroundColor: null,
								plotBorderWidth: null,
								plotShadow: false,
								type: 'pie',
								events: {
									load: function () {
										this.reflow();
									}
								},
								height: 400,
							},
							exporting: {
								buttons: {
									contextButton: {
										menuItems: [{
											text: 'Export to PDF',
											onclick: function () {
												this.exportChart({
													type: 'application/pdf'
												});
											},
											separator: false
										}, {
											text: 'Export to JPG',
											onclick: function () {
												this.exportChart({
													type: 'image/jpeg'
												});
											},
											separator: false
										}, {
											text: 'Export to PNG',
											onclick: function () {
												this.exportChart({
													type: 'image/png'
												});
											},
											separator: false
										}]
									}
								}
							},
							title: {
								text: ''
							},
							credits: {
								enabled: false
							},
							tooltip: {
								headerFormat: '',
								pointFormat: '{point.name}: <b>{point.y} '
									+ data[0]['suffix']
									+ ' ({point.percentage:.1f}%) </b>'
							},
							plotOptions: {
								pie: {
									allowPointSelect: true,
									cursor: 'pointer',
									innerSize: '60%',
									dataLabels: {
										enabled: true,
										style: {
											color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
										}
									},
									showInLegend: false
								}
							},
							series: [data[0]]
						}

						// hide dataLabels when resolution is xxs (phone)
						if ($(window).width() < 480) {
							config.plotOptions.pie.dataLabels.enabled = false
						}
						let chartId = getChartId(id);
						$('#' + chartId).highcharts(config);
					}
				}
		},
		'marketing-chart-all-offers': {
			renderChart: function (data, id) {
				if (data) {
					this.chart(data, 1);
				} else {
					vm.error[id] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[1] = false;
			},
			chart: function (data, id) {
				let config = {
					chart: {
						type: 'funnel',
						events: {
							load: function () {
								this.reflow();
							}
						}
					},
					title: {
						text: '',
						x: -50
					},
					plotOptions: {
						series: {
							dataLabels: {
								enabled: true,
								format: '{point.percentage:.1f}% - <b>{point.name}</b> ({point.y:,.0f})',
								color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
								softConnector: true
							},
							center: ['40%', '50%'],
							neckWidth: '25%',
							neckHeight: '20%',
							width: '65%'
						}
					},
					legend: {
						enabled: true
					},
					series: [{
						name: vm.trans("LANG.OFFERS"),
						data: data
					}]
				};
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);
			}
		},
		'avb-widget-chart-ytd-and-total': {
			renderChart: function (data, id) {
				if (data) {
					this.chart[1](data, 1);
					this.chart[2](data, 2);
				} else {
					vm.error[id] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[1] = false;
			},
			chart: {
				config: {
					chart: {
						events: {
							load: function () {
								this.reflow();
							}
						},
						type: 'solidgauge'
					},
					credits: {
						enabled: false
					},
					exporting: {
						buttons: {
							contextButton: {
								menuItems: [{
									text: 'Export to PDF',
									onclick: function () {
										this.exportChart({
											type: 'application/pdf'
										});
									},
									separator: false
								}, {
									text: 'Export to JPG',
									onclick: function () {
										this.exportChart({
											type: 'image/jpeg'
										});
									},
									separator: false
								}, {
									text: 'Export to PNG',
									onclick: function () {
										this.exportChart({
											type: 'image/png'
										});
									},
									separator: false
								}]
							}
						}
					},
					title: null,
					pane: {
						center: ['50%', '85%'],
						size: '140%',
						startAngle: -90,
						endAngle: 90,
						background: {
							backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
							innerRadius: '60%',
							outerRadius: '100%',
							shape: 'arc'
						}
					},
					tooltip: {
						enabled: false
					},
					// the value axis
					yAxis: {
						stops: [
							[0.25, '#DF5353'], // red
							[0.5, '#DDDF0D'], // yellow
							[0.75, '#55BF3B'] // green
						],
						lineWidth: 0,
						minorTickInterval: null,
						tickAmount: 2,
						title: {
							y: -70
						},
						labels: {
							y: 16
						}
					},
					plotOptions: {
						solidgauge: {
							dataLabels: {
								y: 5,
								borderWidth: 0,
								useHTML: true
							}
						}
					}
				},
				1: function (data, id) {
					let config = Highcharts.merge(this.config, {
						yAxis: {
							min: 0,
							max: 100,
							title: {
								text: "Actual vs Budget YTD"
							}
						},
						credits: {
							enabled: false
						},
						series: [{
							name: data[0]['name'],
							data: [data[0]['data']],
							dataLabels: {
								format: '<div style="text-align:center"><span style="font-size:25px;color:' +
									((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f} %</span><br/>' +
									'<span style="font-size:14px;color:silver">YTD</span></div>'
							},
							tooltip: {
								valueSuffix: ' %'
							}
						}]
					});
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
				2: function (data, id) {
					let config = Highcharts.merge(this.config, {
						yAxis: {
							min: 0,
							max: 100,
							title: {
								text: "Actual vs Budget GEN"
							}
						},
						series: [{
							name: data[1]['name'],
							data: [data[1]['data']],
							dataLabels: {
								format: '<div style="text-align:center"><span style="font-size:25px;color:' +
									((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f} %</span><br/>' +
									'<span style="font-size:14px;color:silver">Total</span></div>'
							},
							tooltip: {
								valueSuffix: ' %'
							}
						}]
					});
					let chartId = getChartId(id);
					$('#' + chartId).highcharts(config);
				},
			}
		},
		'avb-widget-chart-monthly-evolution': {
			renderChart: function (data, id) {
				if (data) {
					this.chart(data, 1);
				} else {
					vm.error[id] = vm.trans("LANG.NO_DATA");
				}
				vm.loading[1] = false;
			},
			chart: function (data, id) {
				let config = {
					chart: {
						events: {
							load: function () {
								setTimeout(() =>this.reflow())
							}
						},
						type: 'column'
					},
					credits: {
						enabled: false
					},
					exporting: {
						buttons: {
							contextButton: {
								menuItems: [{
									text: 'Export to PDF',
									onclick: function () {
										this.exportChart({
											type: 'application/pdf'
										});
									},
									separator: false
								}, {
									text: 'Export to JPG',
									onclick: function () {
										this.exportChart({
											type: 'image/jpeg'
										});
									},
									separator: false
								}, {
									text: 'Export to PNG',
									onclick: function () {
										this.exportChart({
											type: 'image/png'
										});
									},
									separator: false
								}]
							}
						}
					},
					title: {
						text: ''
					},
					xAxis: {
						categories: data.categories
					},
					yAxis: [{
						min: 0,
						title: {
							text: ''
						}
					}, {
						title: {
							text: ''
						},
						opposite: true
					}],
					legend: {
						shadow: false
					},
					tooltip: {
						shared: true
					},
					plotOptions: {
						column: {
							grouping: false,
							shadow: false,
							borderWidth: 0
						}
					},
					series: [{
						name: data['series'][1]['name'],
						color: data['series'][1]['color'],
						data: data['series'][1]['data']
						//pointPadding: 0.3,
						//pointPlacement: 0.2
					}, {
						name: data['series'][0]['name'],
						color: data['series'][0]['color'],
						data: data['series'][0]['data'],
						pointPadding: 0.2
					}]
				};
				let chartId = getChartId(id);
				$('#' + chartId).highcharts(config);
			}
		}
	};
	// chartsConfig['practice-chart-invoiced-effort'] = angular.copy(chartsConfig['practice-chart-monthly-hours']);
	// chartsConfig['managing-chart-due-invoices'] = angular.copy(chartsConfig['controller-chart-due-invoices']);
	let renderChartFn = chartsConfig[$attrs.widgetName].renderChart.bind(chartsConfig[$attrs.widgetName]);
	vm = new DashboardWidgetCtrl($element, $attrs, $scope, $injector, renderChartFn);

	return vm;
})
;
